var menu = $('#navbar-collapse-main');
var subNav = $('.sub-navigation');
var border = true;

if ( $(subNav).length === 0 ) {
    $(window).scroll(function() {

        if ($(this).scrollTop() === 0) { // this refers to window
            menu.removeClass('scroll');
            border = true;
        } else {
            if(border) {
                border = false;
                menu.addClass('scroll');
            }
        }

    });
}