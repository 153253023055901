var container = $('.content__container');
var load_more_btn = $('#upcoming-shows--more_btn');
var filter__btn = $('.detail__filter__list__item');
var filter__btn__home = $('.detail__filter--home__list__item');
var filter__input = $('.detail__filter__list__item--search input');
var filter__input__home = $('.detail__filter--home__list__item--search input');
var main_posters = $('.main-posters');
var filter__open__btn = $('.detail__filter__category');
var filter__open__btn__home = $('.detail__filter__category--home');
var filter__close__btn = $('.detail__filter__close--btn');
var filter__close__btn__home = $('.detail__filter--home__close--btn');
var filter__modal = $('.detail__filter');
var filter__modal__home = $('.detail__filter--home');
var filter_links = $('.detail__filter--home__link');

var filters = {
    type: 'all',
    q: null
};
var filter_in_use = false;

//Load more interaction
load_more_btn.click(function (e) {
    //check for more data with a skip attribute
    load_more_btn.fadeOut('fast');

    filters.skip = items_count;

    if(filter_in_use) {
        filters.type = "all";
    } else {
        filters.type = "overview";
    }

    console.log(filters.current_season);


    filters.current_season = season.replace('season_', '20');

    $.ajax({
        url: url,
        type: 'GET',
        data: filters,
        success: function (response) {
            var items = response.items;

            for (var i = 0; i < items.length; i++) {
                container.append(items[i]);
            }

            items_count += items.length;

            if (items_count < tot_items_count && items.length !== 0) {
                load_more_btn.show();
                load_more_btn.fadeIn('fast');
            }
        }
    });
});

//search
var interval = false;
filter__btn.click(function () {
  filterBtnClick($(this));
});
filter__btn__home.click(function () {
  filterBtnClick($(this));
});


var filterBtnClick = function (item) {
    //set active state
    filter__btn.removeClass('active');
    filter__btn__home.removeClass('active');
    item.addClass('active');

    var type = item.data('type');
    setFilters(type);
    clear();
    doSearch();
}

var setFilters = function (type) {
    var val = $(filter__input).val();

    if(window.location.pathname === "/en" || window.location.pathname === "/fr"  ) {
        val = $(filter__input__home).val();
    }

    switch (type) {
        case 'all':
            filters = {
                type: 'all',
                q: val
            };
            break;
        case 'season_17':
            filters = {
                type: 'all',
                'season_17': true,
                q: val
            };
            break;
        case 'archive':
            filters = {
                type: 'all',
                archive: true,
                q: val
            };
            break;
        case 'season_18':
            filters = {
                type: 'all',
                'season_18': true,
                q: val
            };
            break;
        case 'video':
            filters = {
                type: 'all',
                video: true,
                q: val
            };
            break;
        case 'webcasts':
            filters = {
                type: 'all',
                webcasts: true,
                q: val
            };
            break;
    }
}
filter__input.on('keydown', function (filter) {
    checkInput($(this));
});
filter__input__home.on('keydown', function (filter) {
    checkInput($(this));
});


// Create the listener function
var checkInput = debounce(function (e) {
    clearInterval(interval);
    clear();
    doSearch(e.val());
}, 500); // Maximum run of once per 500 milliseconds

var doSearch = function (val) {
    console.log(val);
    filters.type = "all";
    filter_in_use = true;

    filters.q = val;
    filters.skip = 0;

    show_count = 0;

    $.ajax({
        url: url,
        type: 'GET',
        data: filters,
        success: function (response) {
            var items = response.items;
            items_count = response.items.length;
            tot_items_count = response.tot_items

            clearInterval(interval);
            clear();

            if (items.length === 0) {
                container.append('<h3>' + no_more_text + '</h3>');
            }

            var i = 0;
            interval = setInterval(function () {
                if (i === items.length) {
                    clearInterval(interval);
                }

                container.append(items[i]);
                i++;
            }, 100);


            if (items_count < tot_items_count && items.length !== 0) {
                load_more_btn.fadeIn('fast');
            }
        }
    });
}

//check if there is a hash in the url -> if so filter
if(window.location.hash) {
    var ingnoreHashes = [
        'calender', 'productions-17', 'productions-18', 'about-skagen'
    ];
    var hashtype = myString = window.location.hash.substring(1);

    if($.inArray(hashtype, ingnoreHashes) < 0) {

        setFilters(hashtype);
        doSearch();

        $('.detail__filter__list__item--' + hashtype ).addClass('active');
        $('.detail__filter--home__list__item--' + hashtype ).addClass('active');
    }
}


//modal opening and closing
filter__open__btn.click(function () {
    filter__modal.fadeIn('fast');
    filter__modal__home.fadeIn('fast');
});

filter__open__btn__home.click(function () {
    filter__modal__home.fadeIn('fast');
});

filter__close__btn.click(function(){
    filter__modal.fadeOut('fast');
});

filter__close__btn__home.click(function(){
    filter__modal__home.fadeOut('fast');
});

filter_links.click (function () {
    filter__modal__home.fadeOut('fast');
    filter__modal.fadeOut('fast');
});

var clear = function () {
    load_more_btn.hide();

    //clear the current container
    container.empty();
    main_posters.fadeOut('fast');
}
