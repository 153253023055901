var col__left = $('.app__col--left');
var col__left__wrapper = $('.app__col--left__wrapper');
var col__right__wrapper = $('.app__col--right__wrapper');

var setWidth = function () {
    var width = $(col__left).width();

    $(col__left__wrapper).css('width', width + 'px');
    $(col__right__wrapper).css('width', width + 'px');
}

window.onresize = function(event) {
    setWidth();
}

setWidth();