$('.press__login--form').on('submit', function (e) {
    e.preventDefault();
    //send ajax call

    $.ajax({
        url: pressRoute,
        type: 'POST',
        data: {'email': $('#press__login--email').val()},
        success: function () {
           //do stuff
            window.location.reload();
        }, error: function (){
            $('#press__login--email').addClass('error');
        }
    });

    return false;
});