$(window).on('load', function(){
    var cookieBanner = $('.cookie-banner');

    if (cookieBanner.length > 0) {
        var closeBtn = $('.cookie-banner__close-btn');
        var marketingCookieCheck = $('#cookie-banner-marketing-cookie-check');
        var cookieBannerSubmit = $('#cookie-banner-submit');

        closeBtn.click(function () {
            cookieBanner.hide();
        });

        var cookieBannerCookie = Cookies.get('skagen-cookie-preference-set');

        if (!cookieBannerCookie) {
            cookieBanner.show();
        } else {
            cookieBanner.hide();
        }

        cookieBannerSubmit.click(function (e) {
            e.preventDefault();
            Cookies.set('skagen-cookie-preference-set', "true");
            Cookies.set('skagen-cookie-preference-accept-marketing', JSON.stringify(marketingCookieCheck.prop('checked')));

            if (!marketingCookieCheck.prop('checked')) {
                Cookies.set('_ga', undefined);
                Cookies.set('optOutGoogleTracking', true);
            } else {
                Cookies.set('optOutGoogleTracking', false);
            }

            window.location.reload();

            cookieBanner.hide();
        });
    }
});