$(window).on('load', function(){
    var banner = $('.banner');

    if (banner.length > 0) {
        var closeBtn = $('.banner__close-btn');
        closeBtn.click(function () {
            banner.hide();
        });

        /*
        var id = banner.data('id');
        var closeBtn = $('.banner__close-btn');

        var bannersCookie = Cookies.get('skagen-banners-confirmed');

        if (!bannersCookie) {
            bannersCookie = [];
            console.log('in clear');
        } else {
            bannersCookie = JSON.parse(bannersCookie);
        }

        if (bannersCookie.includes(id)) {
            banner.hide();
        } else {
            banner.show();
        }

        closeBtn.click(function () {
            banner.hide();

            if (!bannersCookie.includes(id)) {
                bannersCookie.push(id);
                Cookies.set('skagen-banners-confirmed', JSON.stringify(bannersCookie));
            }
        });
        */
    }
});