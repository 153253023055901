$('.main-posters').slick({
    dots: false,
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 4000,
     arrows: true,
});
